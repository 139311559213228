<!--
 * @Author: 智客云网络科技
 * @Date: 2022-01-19 23:12:05
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-03-27 18:26:39
 * @Description: 生成账单
 * @FilePath: \src\views\App\Bill\BillManage.vue
-->

<template>
  <div class="vue-box">
    <!-- ------------- 检索 ------------- -->
    <div class="table-search-box">
      <div class="table-search-title">检索</div>
      <div class="table-search-content">
        <div class="table-search-item">
          <label>结算状态：</label>
          <el-select size="small" v-model="p.status">
            <el-option label="所有" :value="150000"></el-option>
            <el-option label="已结算" :value="150010"></el-option>
            <el-option label="未结算" :value="150011"></el-option>
          </el-select>
        </div>

        <div class="table-search-item">
          <label>客户筛选：</label>
          <el-input
            size="small"
            v-model="p.kcu_name"
            @keyup.enter.native="
              p.pageNo = 1;
              _Load();
            "
            suffix-icon="xxxx"
          ></el-input>
        </div>

        <div class="table-search-btn">
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="
              p.pageNo = 1;
              _Load();
            "
            >查询</el-button
          >
        </div>
      </div>
    </div>

    <!-- ------------- 快捷按钮 ------------- -->
    <div class="table-btn">
      <el-button
        size="small"
        type="primary"
        icon="el-icon-plus"
        @click="_OpenCreateExpressCargoModel()"
      >
        导入账单
      </el-button>
      <el-button
        size="small"
        type="info"
        icon="el-icon-refresh"
        @click="_Load()"
        >刷新</el-button
      >
    </div>

    <!-- ------------- 数据列表 ------------- -->
    <el-table
      class="data-table"
      :data="TableData"
      ref="table"
      size="mini"
      stripe
      border
      row-key="id"
    >
      <!-- 多选 -->
      <el-table-column type="selection" align="center" width="55px">
      </el-table-column>

      <!-- ID -->
      <el-table-column
        label="ID"
        prop="Aid"
        align="center"
        width="50px"
      ></el-table-column>

      <!-- 客户昵称 -->
      <el-table-column
        label="客户昵称"
        prop="kcu_name"
        align="center"
        width="130px"
      ></el-table-column>

      <!-- 包裹箱号 -->
      <el-table-column
        label="包裹箱号"
        prop="kpa_index"
        align="center"
        width="80px"
      ></el-table-column>

      <!-- 包裹三边长 -->
      <el-table-column
        label="包裹三边长"
        prop="3l"
        align="center"
        width="130px"
      ></el-table-column>

      <!-- 发件渠道 -->
      <el-table-column
        label="发件渠道"
        prop="kch_name"
        align="center"
        width="150px"
      ></el-table-column>

      <!-- 加固打包 -->
      <el-table-column
        label="加固打包"
        prop="kch_name"
        align="center"
        width="150px"
      ></el-table-column>

      <!-- 清关方式 -->
      <el-table-column
        label="清关方式"
        prop="kch_name"
        align="center"
        width="150px"
      ></el-table-column>

      <!-- 包裹重量 -->
      <el-table-column
        label="包裹重量"
        prop="weight"
        align="center"
        width="150px"
      ></el-table-column>

      <!-- 备注 -->
      <el-table-column label="备注">
        <template slot-scope="s">
          <span>{{ s.row.notes }}</span>
        </template>
      </el-table-column>

      <!-- 操作 -->
      <el-table-column label="操作" align="center" width="200px">
        <template slot-scope="s">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit"
            @click="_OpenUpdateExpressCargoModel(s.row.kec_id)"
            >修改</el-button
          >
          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            @click="_DelExpressCargo(s.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- ------------- 分页 ------------- -->
    <div class="page-box">
      <el-pagination
        background
        layout="total, prev, pager, next, sizes, jumper"
        :current-page.sync="p.pageNo"
        :page-size.sync="p.pageSize"
        :total="p.dataCount"
        :page-sizes="[50, 100, 150, 300]"
        @current-change="_Load()"
        @size-change="_Load()"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getBill, pushBill } from "./api"; //页面专有接口
export default {
  name: "BillManage",
  data() {
    return {
      p: {
        status: 150010,
      },
      loading: null, //加载
      shou_upload: true, //显示文件上传框
      show_bill: false, //显示账单
      file_name: "", //已上传的文件名称
      thisTime: "", //当前日期
      bill_data: [], //账单数据
    };
  },
  methods: {
    //获取账单
    _GetBill() {
      this._OpenLoading(); //启动加载
      getBill({ file_name: this.file_name })
        .then((res) => {
          this._CloseLoading(); //关闭加载
          this.bill_data = res.data;
          this.shou_upload = false; //关闭文件上传
          setTimeout(() => {
            this.show_bill = true;
          }, 500);
        })
        .catch((err) => {
          this._CloseLoading(); //关闭加载
          this.$utils.error(err.err_msg);
        });
    },

    //获取当前日期
    _GetTime() {
      let date = new Date();
      let year = date.getFullYear(); // 年
      let month = date.getMonth() + 1; // 月
      let day = date.getDate(); // 日
      this.thisTime = year + "-" + month + "-" + day;
    },

    //复制账单
    _CopyBill(bill) {
      let bill_html = this._Bill2Html(bill);
      this.$utils.copyText(bill_html);
      this.$utils.success("[ " + bill.name + " ]的账单已复制到剪贴板！");
    },

    //账单转文本格式
    _Bill2Html(bill_config) {
      var html = "";

      const item = bill_config;
      let customer_name = item.name; //客户姓名
      let time = this.$utils.forDate(1); //账单生成日期
      let scc_cost = item.scc_cost; //简易清关费
      let pc_cost = item.pc_cost; //护照清关费
      let material_cost = item.material_cost; //包装材料
      let reinforcement_cost = item.reinforcement_cost; //加固打包费
      let add_cost = item.add_cost; //附加费
      let volume_cost = item.volume_cost; //体积费
      let all_cost = item.all_cost; //总费用
      let bill_items = item.items; //包裹信息

      //账单头部
      var htm_head = "";
      htm_head += "飞鱼国际-运费结算单\r\n";
      // htm_head .= "线路:中=>韩\r\n";
      htm_head += "客户姓名:" + customer_name + "\r\n";
      htm_head += "结算日期:" + time + "\r\n";
      htm_head += "\r\n";

      //账单尾部
      let htm_tail_add = 0; //累计次数
      var htm_tail = "";
      htm_tail += "-- 其他费用\r\n";

      if (scc_cost != 0) {
        htm_tail += "简易清关费：" + scc_cost + "元\r\n";
        htm_tail_add++;
      }

      if (pc_cost != 0) {
        htm_tail += "护照清关费：" + pc_cost + "元\r\n";
        htm_tail_add++;
      }

      if (material_cost != 0) {
        htm_tail += "包装材料费：" + material_cost + "元\r\n";
        htm_tail_add++;
      }

      if (reinforcement_cost != 0) {
        htm_tail += "加固打包费：" + reinforcement_cost + "元\r\n";
        htm_tail_add++;
      }

      if (volume_cost != 0) {
        htm_tail += "体积费：" + volume_cost + "元\r\n";
        htm_tail_add++;
      }

      if (add_cost != 0) {
        htm_tail += "附加费：" + add_cost + "元\r\n";
        htm_tail_add++;
      }

      if (htm_tail_add === 0) {
        htm_tail = "";
      } else {
        htm_tail += "\r\n";
      }

      htm_tail += "-- 费用合计\r\n";
      htm_tail += "合计(CNY)：" + all_cost + "元\r\n";

      //创建账单体
      if (typeof bill_items !== "object") {
        return this.$utils.msg("账单生成失败，请稍后再试！");
      }

      var htm_content = "";

      for (let bi = 0; bi < bill_items.length; bi++) {
        const bitem = bill_items[bi];

        let index = bitem["index"]; //包裹序号
        let channel = bitem["channel"]; //渠道
        let weight = bitem["weight"]; //重量
        let basic_cost = bitem["basic_cost"]; //运费

        htm_content += "-- 包裹" + index + "-" + channel + "\r\n";
        htm_content += "重量：" + weight + "kg\r\n";
        htm_content += "运费：" + basic_cost + "元\r\n";
        htm_content += "\r\n";
      }

      html += htm_head + htm_content + htm_tail;
      return html;
    },

    //推送账单到群聊
    _PushBill(bill_config) {
      pushBill({ bill_config: JSON.stringify(bill_config) })
        .then((res) => {
          this.$utils.success(res.err_msg);
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //开启加载框
    _OpenLoading() {
      this.loading = this.$loading({
        lock: true,
        text: "账单生成中，请稍后……",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.8)",
      });
    },

    //关闭加载框
    _CloseLoading() {
      setTimeout(() => {
        this.loading.close();
      }, 500);
    },
  },
  mounted() {
    this._GetTime();
  },
};
</script>
<style>
.upload {
  height: 100%;
  margin: auto;
  text-align: center;
}

.bill-con {
  height: auto;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  font-family: Tahoma, Helvetica, Arial, "宋体", sans-serif;
}

.el-card__body {
  overflow-y: auto;
  height: 360px;
}

/*滚动条的宽度*/
.el-card__body::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}

/*外层轨道。可以用display:none让其不显示，也可以添加背景图片，颜色改变显示效果*/
.el-card__body::-webkit-scrollbar-track {
  width: 6px;
  background-color: #f1f1f1;
  -webkit-border-radius: 1em;
  -moz-border-radius: 1em;
  border-radius: 1em;
}

/*滚动条的设置*/
.el-card__body::-webkit-scrollbar-thumb {
  background-color: #e0e0e0;
  background-clip: padding-box;
  min-height: 15px;
  -webkit-border-radius: 1em;
  -moz-border-radius: 1em;
  border-radius: 1em;
}
</style>
